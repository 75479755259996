import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import "../components/css/policy.css";
import { navigate } from "gatsby";

export default function TermsOfUse() {
  const onContentPolicyClick = () => {
    navigate("/content-policy");
  }
  const onServiceTermsClick = () => {
    navigate("/service-terms");
  }
  const onSharingPublicationPolicyClick = () => {
    navigate("/sharing-publication-policy");
  }
  const onUsagePolicyClick = () => {
    navigate("/usage-policy");
  }
  const onPrivacyPolicyClick = () => {
    navigate("/privacy-policy");
  }
  
  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Thank you for using 99Gens!</h3>
              <h3>TERMS OF USE</h3>
              <p>
                In order to avail yourself of the various products and services
                offered by 99GENS LLC and its affiliates, including but not limited to
                software, tools, data, documentation, and website, as well as our
                application programming interface and developer services, you must
                abide by these Terms of Use. These Terms encompass a range of other
                policies and guidelines, including our
                <a onClick={onContentPolicyClick}>{` Content Policy`}</a>,
                <a onClick={onServiceTermsClick}>{` Service Terms`}</a>,
                <a onClick={onSharingPublicationPolicyClick}> Sharing &amp; Publication Policy</a>, and 
                <a onClick={onUsagePolicyClick}> Usage Policies</a>, as well as
                any other documentation or policies that we may furnish in writing
                from time to time. Your use of our Services indicates your acceptance
                of these Terms. Additionally, our
                <a onClick={onPrivacyPolicyClick}>{` Privacy Policy`}</a> lays out our
                protocols for gathering and utilizing personal information.
              </p>
              <h3>1. Registration and Access</h3>
              <p>
                To utilize the Services, you must have attained the minimum age of 13
                years. In the event that you are under the age of 18, you will need to
                procure the consent of your parent or legal guardian before utilizing
                the Services. In instances where you utilize the Services on behalf of
                another individual or entity, you must be duly authorized to accept
                these Terms on their behalf. Accurate and comprehensive information
                must be furnished during account registration. Access credentials and
                accounts must not be divulged to parties outside your organization,
                and you will bear full responsibility for all activities conducted
                using your credentials.
              </p>
              <h3>2. Usage Requirements</h3>
              <p>
                (a) Utilizing the Services: Pursuant to these Terms, you are
                authorized to access and utilize the Services, subject to compliance
                with applicable laws and these Terms. We, along with our affiliates,
                reserve all rights, title, and interest to the Services.
              </p>
              <p>
                (b) Feedback: We value feedback, comments, proposals, ideas, and
                suggestions for enhancements to our Services. In the event that you
                provide any such information, we may employ it without compensation or
                restriction to you.
              </p>
              <p className="section">
                (c) Limitations: You may not (i) use the Services in a manner that
                violates or infringes on any person's rights; (ii) endeavor to reverse
                assemble, reverse compile, decompile, translate, or in any other way
                attempt to discover the source code or underlying components of the
                Services' models, algorithms, and systems, except where such
                prohibitions conflict with relevant laws; (iii) Utilize the Services
                to develop any technology, including foundation models or other
                large-scale models, without any limitations on their potential to
                compete with other technologies.; (iv) utilize any methods, other than
                those authorized through the API, to extract data from the Services,
                including web data extraction methods, web scraping, or web
                harvesting; (v) misrepresent that output from the Services is
                human-generated when it is not; or (vii) trade, sell, or transfer API
                keys without our prior consent. It is incumbent on you to adhere to
                any rate limits and other requirements delineated in our
                documentation. You may exclusively utilize Services in jurisdictions
                that are currently supported by 99GENS LLC.
              </p>
              <p className="section">
                (d) Third-Party Services: In the event that you utilize third-party
                software, services, or other products in association with our
                Services, such products are subject to their respective terms of
                service, and we assume no liability for third-party products.
              </p>
              <h3>3. Content</h3>
              <p className="section">
                (a) Your Content: You have the option to provide Input to the
                Services, which generates Output that is based on the Input, thereby
                creating Content. Input and Output, taken together, constitute
                Content. To the extent allowed by applicable law, and in relation to
                compliance with these Terms, you retain ownership of Input, and 99GENS
                LLC assigns you all of its right, title, and interest in and to
                Output. 99GENS LLC reserves the right to utilize Content as required
                to deliver and maintain the Services, abide by applicable laws, and
                implement our policies. You are accountable for Content, including
                ensuring that it does not violate any applicable law or these Terms.
              </p>
              <p className="section">
                (b) Similarity of Content: Due to the nature of machine learning, the
                Generative APIs may generate Output that is duplicated across multiple
                users, including both 99GENS LLC and third-party users. This means
                that different users making similar requests may receive identical or
                similar Output. For instance, if you request a particular textile
                pattern, the API may provide the same pattern to other users who
                submit similar requests. While it is unlikely, in the event that the
                Generative APIs produce the same Output for multiple users, it is
                important to note that such Output does not constitute the exclusive
                property of any individual user and cannot be considered as their
                Content. As such, In the event of any dispute arising regarding
                ownership of such Output, the primary user who first publishes it on
                our platform shall be deemed the rightful owner and awarded the rights
                to use and distribute it. If you feel someone else is using your
                content e-mail 
                <a href="/cdn-cgi/l/email-protection#16727f656663627365562f2f717378653875797b"><span className="__cf_email__"
                    data-cfemail="bcd8d5cfccc9c8d9cffc8585dbd9d2cf92dfd3d1"> [email&#160;protected] </span></a>
                to create a dispute.
              </p>

              <p className="section">
                (c) Limitations: You may not (i) use the Services in a manner that violates or
                infringes on any person's rights; (ii) endeavor to reverse assemble, reverse
                compile, decompile, translate, or in any other way attempt to discover the
                source code or underlying components of the Services' models, algorithms, and
                systems, except where such prohibitions conflict with relevant laws; (iii)
                utilize any methods, other than those authorized through the API, to extract
                data from the Services, including web data extraction methods, web scraping,
                or web harvesting; (iv) misrepresent that output from the Services is
                human-generated when it is not; or (v) trade, sell, or transfer API keys
                without our prior consent. It is incumbent on you to adhere to any rate limits
                and other requirements delineated in our documentation. You may exclusively
                utilize Services in jurisdictions that are currently supported by 99GENS LLC.
              </p>
              <p className="section">
              (d) Third-Party Services: In the event that you utilize third-party
              software, services, or other products in association with our
              Services, such products are subject to their respective terms of
              service, and we assume no liability for third-party products.
              </p>

              <h3>3. Content</h3>
              <p className="section">
                (a) Your Content: You have the option to provide Input to the
                Services, which generates Output that is based on the Input, thereby
                creating Content. Input and Output, taken together, constitute
                Content. To the extent allowed by applicable law, and in relation to
                compliance with these Terms, you retain ownership of Input, and 99GENS
                LLC assigns you all of its right, title, and interest in and to
                Output. 99GENS LLC reserves the right to utilize Content as required
                to deliver and maintain the Services, abide by applicable laws, and
                implement our policies. You are accountable for Content, including
                ensuring that it does not violate any applicable law or these Terms.
              </p>
              <p className="section">
                (b) Similarity of Content: Due to the nature of machine learning, the
                Generative APIs may generate Output that is duplicated across multiple
                users, including both 99GENS LLC and third-party users. This means
                that different users making similar requests may receive identical or
                similar Output. For instance, if you request a particular textile
                pattern, the API may provide the same pattern to other users who
                submit similar requests. While it is unlikely, in the event that the
                Generative APIs produce the same Output for multiple users, it is
                important to note that such Output does not constitute the exclusive
                property of any individual user and cannot be considered as their
                Content. As such, in the event of any dispute arising regarding
                ownership of such Output, the primary user who first publishes it on
                our platform shall be deemed the rightful owner and awarded the rights
                to use and distribute it. If you feel someone else is using your
                content email
                <a href="mailto:disputes@99gens.com">{` disputes@99gens.com`}</a> to create
                a dispute.
              </p>
              <p className="section">
                (c) Use of Content to Improve Services: We do not employ Content that
                you furnish to or receive from our API ("API Content") to enhance or
                develop our Services. API Content is exclusively utilized to provide
                and maintain our API Services. We may use Content from Services other
                than our API ("Non-API Content") to assist in developing and improving
                our Services. Please note that this may curtail the Services' ability
                to better address your specific use case in some situations.
              </p>
              <p className="section">
                (d) Copyright Complaints: In the event that you suspect that your
                intellectual property rights have been infringed, please send a notice
                to the address provided below. We may delete or disable Content that
                is alleged to infringe on intellectual property rights and may
                terminate the accounts of repeat infringers.
              </p>
              <p className="section">
                99GENS LLC<br />
                651 N. BROAD ST., SUITE 201<br />
                MIDDLETOWN DE 19709<br />
                or, by electronic mail:
                <a href="mailto:copyrights@99gens.com">{` copyrights@99gens.com`}</a>
              </p>

              <ul>
                <li>
                  A physical or electronic signature of the person authorized to act
                  on behalf of the owner of the copyright interest;
                </li>
                <li>
                  A description of the copyrighted work that you claim has been
                  infringed upon;
                </li>
                <li>
                  A description of where the material that you claim is infringing is
                  located on the site;
                </li>
                <li>Your address, telephone number, and e-mail address;</li>
                <li>
                  A statement by you that you have a good-faith belief that the
                  disputed use is not authorized by the copyright owner, its agent, or
                  the law; and
                </li>
                <li>
                  A statement by you, made under penalty of perjury, that the above
                  information in your notice is accurate and that you are the
                  copyright owner or authorized to act on the copyright owner’s
                  behalf.
                </li>
              </ul>

              <h3>4. Fees and Payments</h3>

              <p>
                (a) Fees and Billing: You are required to remit all Fees charged to
                your account in accordance with the prices and terms specified on the
                applicable pricing page or as otherwise agreed upon in writing. Even
                after invoicing or receipt of payment, we reserve the right to rectify
                pricing mistakes or errors. It is incumbent on you to furnish complete
                and accurate billing information, including a valid and authorized
                payment method. We will charge your payment method on a periodic
                basis, subject to reasonable modification of the posting date. You
                authorize us, as well as our affiliates and third-party payment
                processors, to charge your payment method for the Fees. If your
                payment is unable to be processed, we will provide you with written
                notification and may suspend your access to the Services until payment
                is received. Fees are payable in U.S. dollars and are due upon
                issuance of the invoice. Payments are non-refundable, except as
                explicitly provided in this Agreement.
              </p>

              <p>
                (b) Taxes: Unless otherwise specified, Fees do not include federal,
                state, local, or foreign taxes, duties, or comparable assessments
                ("Taxes"). You are accountable for all Taxes associated with your
                purchase, excluding Taxes based on our net income, and we may invoice
                you for such Taxes. You agree to timely pay such Taxes and supply us
                with documentation demonstrating the payment, or other evidence that
                we may reasonably require. We employ the name and address listed in
                your account registration as the place of supply for tax purposes;
                hence, you must ensure this information remains accurate and
                up-to-date.
              </p>

              <p>
                (c) Merchant Processing Fees and Hard Costs: Merchant Processing Fees
                and Hard Costs incurred by 99GENS LLC are not refundable under any
                circumstances.
              </p>

              <p>
                (d) Price Changes: We will notify you via email about any upcoming
                price changes at least 14 days in advance of the change. Unless
                required by law or regulation, or if the change is passed through from
                our third-party providers, any changes to pricing will become
                effective on the date specified in the notification email, which will
                be at least 14 days after the date of the notification. We reserve the
                right to change our prices at any time by providing notice to your
                account and/or on our website. Any changes to pricing will apply to
                Fees charged to your account immediately after the effective date of
                the modifications.
              </p>

              <p>
                (e) Disputes and Late Payments: In the event of a dispute concerning
                Fees or Taxes, please contact
                <a href="mailto:ar@99gens.com">{` ar@99gens.com`}</a> within 30 days of the
                invoice's date of dispute. Uncontested amounts that are past due may
                be subject to a finance charge of 1.5% of the unpaid balance per
                month. If any portion of your Fees is overdue, we may suspend your
                access to the Services after issuing written notification of late
                payment.
              </p>

              <p>
                (f) Free Tier: You are not permitted to create more than one account
                in order to benefit from credits offered in the free tier of the
                Services. If we suspect that you are not utilizing the free tier in
                good faith, we may impose standard fees or discontinue your access to
                the Services.
              </p>

              <h3>5. Confidentiality, Security and Data Protection</h3>
              <p>
                (a) Confidentiality: As an authorized user of 99GENS LLC's products
                and services, you may be granted access to confidential information
                belonging to 99GENS LLC, its affiliates, or third parties. You are
                permitted to use such confidential information only as necessary to
                utilize the services offered under these Terms. You are not allowed to
                disclose any confidential information to any third party, and you must
                take adequate measures to safeguard the confidential information in
                the same way you protect your own confidential information of a
                similar nature, using at least reasonable care. Confidential
                information refers to non-public information that 99GENS LLC or its
                affiliates or third parties designate as confidential, or that should
                reasonably be considered as confidential under the circumstances,
                including software, specifications, and other non-public business
                information. Confidential information does not include information
                that (i) is or becomes publicly available through no fault of yours;
                (ii) you already possess without any confidentiality obligations when
                you received it under these Terms; (iii) is rightfully disclosed to
                you by a third party without any confidentiality obligations; or (iv)
                you independently developed without using any confidential
                information. You may disclose confidential information when required
                by law or the valid order of a court or other governmental authority,
                provided that you give reasonable prior written notice to 99GENS LLC
                and use reasonable efforts to limit the scope of disclosure, including
                assisting us with challenging the disclosure requirement, in each case
                where possible.
              </p>

              <p>
                (b) Security: As a user of the Services, you are responsible for
                implementing reasonable and appropriate measures to secure your access
                to and use of the Services. If you become aware of any vulnerabilities
                or breaches related to your use of the Services, you must promptly
                notify 99GENS LLC and provide details of the vulnerability or breach.
              </p>

              <p>
                (c) Processing of Personal Data: If your use of the Services involves
                the processing of personal data, you are required to provide legally
                adequate privacy notices and obtain necessary consents for the
                processing of such data. You represent to us that you are processing
                such data in accordance with applicable law. If you are governed by
                the GDPR or CCPA and will be using 99GENS LLC for the processing of
                "personal data" as defined in the GDPR or "Personal Information,"
                please fill out <a href="https://forms.gle/LaT8tqiv6Q3Zj3ai8" target="_blank">{` this form `}</a> to request to
                execute our
                Data Processing Addendum.
              </p>

              <h3>6. Term and Termination</h3>

              <p>
                (a) Termination: These Terms shall become effective upon your first
                use of the Services and shall remain in effect until terminated. You
                have the right to terminate these Terms at any time and for any reason
                by discontinuing your use of the Services and Content. We have the
                right to terminate these Terms for any reason by providing you with at
                least 30 days' advance notice. We also have the right to terminate
                these Terms immediately upon notice to you if you materially breach
                Sections 2 (Usage Requirements), 5 (Confidentiality, Security and Data
                Protection), 8 (Dispute Resolution), or 9 (General Terms), if there
                are changes in relationships with third-party technology providers
                outside of our control, or to comply with law or government requests.
              </p>

              <p>
                (b) Effect of Termination: Upon termination, you must stop using the
                Services, and you shall promptly return or destroy any confidential
                information as instructed by us. Sections of these Terms that by their
                nature should survive termination or expiration shall survive,
                including but not limited to Sections 3 (Ownership and License), 5
                (Confidentiality, Security and Data Protection), 6 (Indemnification),
                7 (Limitations of Liability), and 8 (Dispute Resolution).
              </p>

              <h3>
                7. Indemnification; Disclaimer of Warranties; Limitations on Liability
              </h3>

              <p>
                (a) Indemnification: You agree to defend, indemnify, and hold harmless
                us, our affiliates, and our personnel from any claims, losses, and
                expenses (including attorneys' fees) arising from or related to your
                use of the Services, including your Content, products, or services
                developed or offered in connection with the Services, and your breach
                of these Terms or violation of applicable law.
              </p>

              <p>
                (b) Disclaimer of Warranties: THE SERVICES ARE PROVIDED "AS IS." WE,
                OUR AFFILIATES, AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED,
                STATUTORY, OR OTHERWISE) WITH RESPECT TO THE SERVICES AND DISCLAIM ALL
                WARRANTIES, INCLUDING BUT NOT LIMITED TO WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY
                QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES
                ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT
                THAT THE SERVICES WILL BE UNINTERRUPTED, ACCURATE, OR ERROR-FREE, OR
                THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR ALTERED.
              </p>

              <p>
                (c) Limitation of Liability: NEITHER WE NOR ANY OF OUR AFFILIATES OR
                LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF
                PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY
                UNDER THESE TERMS SHALL NOT EXCEED THE GREATER OF THE AMOUNT YOU PAID
                FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS
                BEFORE THE LIABILITY AROSE OR ONE HUNDRED DOLLARS ($100). THE
                LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW. YOU ACKNOWLEDGE AND AGREE THAT THESE LIMITATIONS
                AND DISCLAIMERS ARE ESSENTIAL ELEMENTS OF THESE TERMS, AND THAT ABSENT
                SUCH LIMITATIONS AND DISCLAIMERS, WE WOULD NOT ENTER INTO THESE TERMS.
              </p>

              <h3>8. Dispute Resolution</h3>

              <p>
                <strong>YOU AGREE TO THE FOLLOWING MANDATORY MEDIATION AND CLASS ACTION
                  WAIVER PROVISIONS:</strong>
              </p>

              <p>
                (a) MANDATORY MEDIATION. You and 99GENS LLC agree to resolve any
                claims relating to these Terms or our Services through final and
                binding mediation, except that you have the right to opt out of these
                mediation terms, and future changes to these mediation terms, by
                filling out <a href="https://forms.gle/z3kMB3KegjnTLecL9" target="_blank">{` this form `}</a>
                within 30 days of agreeing to these mediation terms or the relevant
                changes.
              </p>

              <p>
                (b) Informal Dispute Resolution. We would like to understand and try
                to address your concerns prior to formal legal action. Before filing a
                claim against 99GENS LLC, you agree to try to resolve the dispute
                informally by sending us notice at <a href="mailto:legal@99gens.com">{`legal@99gens.com`}</a> of your name, a
                description of the dispute, and the relief you seek. If we are unable
                to resolve a dispute within 60 days, you may bring a formal
                proceeding. Any statute of limitations will be tolled during the
                60-day resolution process.
              </p>

              <h3>8. Dispute Resolution</h3>

              <p>
                <strong>YOU AGREE TO THE FOLLOWING MANDATORY MEDIATION AND CLASS ACTION
                  WAIVER PROVISIONS:</strong>
              </p>

              <p>
                (a) MANDATORY MEDIATION. You and 99GENS LLC agree to resolve any
                claims relating to these Terms or our Services through final and
                binding mediation, except that you have the right to opt out of these
                mediation terms, and future changes to these mediation terms, by
                filling out
                <a href="https://www.99gens.com/opt-out-form" target="_blank">{` this form `}</a>
                within 30 days of agreeing to these mediation terms or the relevant
                changes.
              </p>

              <p>
                (b) Informal Dispute Resolution. We would like to understand and try
                to address your concerns prior to formal legal action. Before filing a
                claim against 99GENS LLC, you agree to try to resolve the dispute
                informally by sending us notice at
                <a href="mailto:legal@99gens.com">{` legal@99gens.com`}</a> of your name, a
                description of the dispute, and the relief you seek. If we are unable
                to resolve a dispute within 60 days, you may bring a formal
                proceeding. Any statute of limitations will be tolled during the
                60-day resolution process.
              </p>

              <p>
                (c) Mediation Forum. Either party may commence mediation through a
                mutually agreed alternative dispute resolution provider. The parties
                will pay equal shares of the mediation fees. If the mediator finds
                that you cannot afford to pay the mediation fees and cannot obtain a
                waiver, 99GENS LLC will pay them for you. 99GENS LLC will not seek its
                attorneys’ fees and costs in mediation unless the mediator determines
                that your claim is frivolous.
              </p>

              <p>
                (d) Mediation Procedures. The mediation will be conducted by
                telephone, based on written submissions, video conference, or on Zoom
                or at another mutually agreed location. The mediation will be
                conducted by a sole mediator under its then-prevailing rules. All
                issues are for the mediator to decide, including but not limited to
                issues relating to the scope, enforceability, and arbitrability of
                this Section. The amount of any settlement offer will not be disclosed
                to the mediator by either party until after the mediator determines
                the final award, if any.
              </p>

              <p>
                (e). Exceptions. This mediation clause does not require mediation of
                the following claims: (i) individual claims brought in small claims
                court; and (ii) injunctive or other equitable relief to stop
                unauthorized use or abuse of the Services or intellectual property
                infringement.
              </p>

              <p>
                (f) NO CLASS ACTIONS. Disputes must be brought on an individual basis
                only, and may not be brought as a plaintiff or class member in any
                purported class, consolidated, or representative proceeding. Class
                mediations, class actions, private attorney general actions, and
                consolidation with other mediations are not allowed. If for any reason
                a dispute proceeds in court rather than through mediation, each party
                knowingly and irrevocably waives any right to trial by jury in any
                action, proceeding, or counterclaim. This does not prevent either
                party from participating in a class-wide settlement of claims.
              </p>

              <p>
                (g) Severability. If any part of this Section 8 is found to be illegal
                or unenforceable, the remainder will remain in effect, except that if
                a finding of partial illegality or unenforceability would allow class
                or representative mediation, this Section 8 will be unenforceable in
                its entirety. Nothing in this Section will be deemed to waive or
                otherwise limit the right to seek public injunctive relief or any
                other non-waivable right, pending a ruling on the substance of such
                claim from the mediator.
              </p>

              <h3>9. General Terms</h3>

              <p>
                (a) Relationship of the Parties. These Terms do not create a
                partnership, joint venture or agency relationship between you and
                99GENS LLC or any of 99GENS LLC affiliates. 99GENS LLC and you are
                independent contractors and neither party will have the power to bind
                the other or to incur obligations on the other’s behalf without the
                other party’s prior written consent.
              </p>

              <p>
                (b) Use of Brands. You may not use 99GENS LLC’s or any of its
                affiliates’ names, logos, or trademarks, without our prior written
                consent.
              </p>

              <p>
                (c) U.S. Federal Agency Entities. The Services were developed solely
                at private expense and are commercial computer software and related
                documentation within the meaning of the applicable U.S. Federal
                Acquisition Regulation and agency supplements thereto.
              </p>

              <p>
                (d) Assignment and Delegation. You may not assign or delegate any
                rights or obligations under these Terms, including in connection with
                a change of control. Any purported assignment and delegation shall be
                null and void. We may assign these Terms in connection with a merger,
                acquisition or sale of all or substantially all of our assets, or to
                any affiliate or as part of a corporate reorganization.
              </p>

              <p>
                (e) Modifications. We may amend these Terms from time to time by
                posting a revised version on the website, or if an update materially
                adversely affects your rights or obligations under these Terms we will
                provide notice to you either by emailing the email associated with
                your account or providing an in-product notification. Changes will
                become effective no sooner than 30 days after we notify you. All other
                changes will be effective immediately. Your continued use of the
                Services after any change means you agree to such change.
              </p>

              <p>
                (f) Notices. All notices will be in writing. We may notify you using
                the registration information you provided or the email address
                associated with your use of the Services. Service will be deemed given
                on the date of receipt if delivered by email or on the date sent via
                courier if delivered by post. 99GENS LLC accepts service of process at
                this address: 99GENS LLC., 651 N. BROAD ST., SUITE 201, MIDDLETOWN DE
                19709, Attn: <a href="mailto:legal@99gens.com">{` legal@99gens.com`}</a>.
                Meetings to discuss disputes shall take place via ZOOM.
              </p>

              <p>
                (g) Waiver and Severability. If you do not comply with these Terms,
                and 99GENS LLC does not take action right away, this does not mean
                99GENS LLC is giving up any of our rights. Except as provided in
                Section 8, if any part of these Terms is determined to be invalid or
                unenforceable by a court of competent jurisdiction, that term will be
                enforced to the maximum extent permissible and it will not affect the
                enforceability of any other terms.
              </p>

              <p>
                (h) Export Controls. The Services may not be used in or for the
                benefit of, exported, or re-exported (a) into any U.S. embargoed
                countries (collectively, the “Embargoed Countries”) or (b) to anyone
                on the U.S. Treasury Department’s list of Specially Designated
                Nationals, any other restricted party lists (existing now or in the
                future) identified by the Office of Foreign Asset Control, or the U.S.
                Department of Commerce Denied Persons List or Entity List, or any
                other restricted party lists. You represent and warrant that you are
                not located in any Embargoed Countries and not on any such restricted
                party lists. You must comply with all applicable laws related to
                Embargoed Countries or Restricted Party Lists, including any
                requirements or obligations to know your end users directly.
              </p>

              <p>
                (i) Equitable Remedies. You acknowledge that if you violate or breach
                these Terms, it may cause irreparable harm to 99GENS LLC and its
                affiliates, and 99GENS LLC shall have the right to seek injunctive
                relief against you in addition to any other legal remedies.
              </p>

              <p>
                (j) Entire Agreement. These Terms and any policies incorporated in
                these Terms contain the entire agreement between you and 99GENS LLC
                regarding the use of the Services and, other than any Service-specific
                terms of use or any applicable enterprise agreements, supersedes any
                prior or contemporaneous agreements, communications, or understandings
                between you and 99GENS LLC on that subject.
              </p>

              <p>
                (k) Jurisdiction, Venue and Choice of Law. These Terms will be
                governed by the laws of the State of California, excluding
                California’s conflicts of law rules or principles. Except as provided
                in the “Dispute Resolution” section, all claims arising out of or
                relating to these Terms will be brought exclusively in the federal or
                state courts of Los Angeles, California USA, with any necessary court
                appearances conducted via Zoom, unless otherwise mandated in person by
                the courts.
              </p>

              <p>
                <a onClick={() => window.scrollTo(0, 0)}>Return to top</a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
